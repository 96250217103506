(function($, app) {
    'use strict';

    app.masonry = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            namespace       = 'Masonry',
            prefix          = 'js-masonry';

        var defaults        = {
            enabled: ['SM', 'MD', 'LG', 'XL'],
            target: '.' + prefix + '-item',
            itemSelector: '.' + prefix + '-item',
            columnWidth: $('.' + prefix).outerWidth() /12,
            percentPosition: true
        };

        // Merge data-config with defaults
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);

        var $targets,
            masonry;


        /* ------------------------------------------ */
        /**
        *   Masonry -> Initialize
        */
        function initialize() {
            if (config.target !== null);

            $targets = $(config.target, $element);

            console.group('[Module] Masonry => fn.Initialize');
            console.log('Config: ', config);
            console.log('Targets ($): ', $targets);
            console.groupEnd();

            if ($targets.length > 0) {
                addEventListeners();
            }
        }


        /* ------------------------------------------ */
        /**
        *   Masonry -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            $win
                .on('resize.' + namespace, initMasonry)
                .trigger('resize.' + namespace);
        }


        /* ------------------------------------------ */
        /**
        *   Masonry -> initMasonry
        */
        function initMasonry() {
            var view    = helper.returnView(),
                enabled = config.enabled;

            if (enabled.indexOf(view) === -1) return;

            masonry = new Masonry('.' + prefix, config);
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
