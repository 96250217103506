(function($, app) {
    'use strict';

    app.search = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            blazy           = new app.blazy(),
            unique          = helper.returnUniqueHash(),
            namespace       = 'Search-' + unique,
            prefix          = 'c-search';

        var defaults        = {};

        // Merge data-config with default settings
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);

        var $close  = $('.' + prefix + '-close');

        var $wrp    = $('.' + prefix + '-wrp'),
            $input  = $('.' + prefix + '-form__input');


        /* ------------------------------------------ */
        /**
        *   Search -> Initialize
        */
        function initialize() {
            addEventHandlers();
        }


        /* ------------------------------------------ */
        /**
        *   Search -> addEventHandlers
        */
        function addEventHandlers() {
            $element.on('click.' + namespace, handleClick);

            $close.on('click.' + namespace, close);

            $doc.on('keyup.' + namespace, function(event) {
                var key = event.keyCode || event.which;

                if (key == 27) {
                    if ($input.is(':focus') && $.trim($input.val()) !== '') {
                        $input.val('');

                    } else {
                        close();
                    }
                }
            });
        }


        /* ------------------------------------------ */
        /**
        *   Search -> HandleClick
        */
        function handleClick() {
            if (!$wrp.hasClass(prefix + '-wrp--visible')) {
                setTimeout( function() {
                    $input.focus().select();
                }, 200);
            }

            $wrp.toggleClass(prefix + '-wrp--visible', !$wrp.hasClass(prefix + '-wrp--visible'));
        }


        /* ------------------------------------------ */
        /**
        *   Search -> close
        */
        function close() {
            $wrp.removeClass(prefix + '-wrp--visible');
            $input.val('');
        }

        initialize();

    };
})(jQuery, window.app = window.app || {});
