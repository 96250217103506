(function($, app) {
    'use strict';

    app.sticky = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            unique          = helper.returnUniqueHash(),
            namespace       = 'Sticky-' + unique,
            prefix          = 'c-prefix';

        var defaults        = {
            enabled: ['SM', 'MD', 'LG', 'XL'],
            stick: true,
            prefix: prefix,
            offset: $element.offset().top,
            position: {
                top: 0,
                bottom: 0,
                current: 0
            }
        };

        // Merge data-config with defaults
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);

        var $win    = $(window),
            $header = $('.c-header'),
            $footer = $('.c-footer');

        var hHeight = $header.outerHeight(),
            eHeight = $element.outerHeight();


        /* ------------------------------------------ */
        /**
        *   sticky -> Initialize
        */
        function initialize() {
            prefix = config.prefix;

            addEventListeners();
        }


        /* ------------------------------------------ */
        /**
        *   sticky -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            $win
                .on('resize.' + namespace, handleResize)
                .trigger('resize.' + namespace);

            $win
                .on('scroll.' + namespace, throttle( handleScroll, 50))
                .trigger('scroll.' + namespace);
        }

        /* ------------------------------------------ */
        /**
        *   sticky -> Initialize
        */
        function handleResize() {
            var viewport    = helper.returnView(),
                enabled     = config.enabled;

            config.stick = enabled.indexOf(viewport) > -1;

            hHeight = $header.outerHeight();
            eHeight = $element.outerHeight();

            if (!config.stick) {
                $element.css({
                    'transform': 'translateY(0px)',
                    '-moz-transform': 'translateY(0px)',
                    '-webkit-transform': 'translateY(0px)'
                });

            } else {
                // config.offset = $element.offset().top;
            }
        }


        /* ------------------------------------------ */
        /**
        *   sticky -> Initialize
        */
        function handleScroll() {
            if (config.stick) {
                var scrollTop   = $win.scrollTop(),
                    fTop        = $footer.offset().top;
                    eHeight     = $element.outerHeight();

                config.position.top     = config.offset - hHeight;
                config.position.bottom  = fTop - eHeight;

                console.group('[module] Sticky > fn.HandleScroll');
                console.log(scrollTop, eHeight, (scrollTop + eHeight));
                console.log((scrollTop + eHeight) < config.position.bottom);

                if (
                    (scrollTop > config.position.top) &&
                    ((scrollTop + eHeight) < config.position.bottom)
                ) {
                    console.log('Sticky: ', true);
                    config.position.current = (scrollTop + hHeight) - config.offset;
                    $element.addClass(prefix + '--sticky');

                } else {
                    console.log('Sticky: ', false);
                    if ((scrollTop < config.position.top)) {
                        config.position.current = 0;

                    } else {
                        config.position = config.position;
                    }

                    $element.removeClass(config.prefix + '--sticky');
                }

                console.log('Position.current: ', config.position.current);
                console.groupEnd();

                $element
                    .css({
                        'transform': 'translateY(' + config.position.current + 'px)',
                        '-moz-transform': 'translateY(' + config.position.current + 'px)',
                        '-webkit-transform': 'translateY(' + config.position.current + 'px)'
                    });
            }
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
