(function($, app) {
    'use strict';

    app.sameheight = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            namespace       = 'Sameheight';

        var defaults        = {
            resizeEnabled: ['MD', 'LG', 'XL'],
            target: null
        };

        // Merge data-config with defaults
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);

        var $targets;


        /* ------------------------------------------ */
        /**
        *   Sameheight -> Initialize
        */
        function initialize() {
            if (config.target !== null);

            $targets = $(config.target, $element);

            console.group('[Module] SameHeight => fn.Initialize');
            console.log('Config: ', config);
            console.log('Targets ($): ', $targets);
            console.groupEnd();

            if ($targets.length > 0) {
                addEventListeners();
            }
        }


        /* ------------------------------------------ */
        /**
        *   Sameheight -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            $win
                .on('resize.' + namespace, adjustHeights)
                .trigger('resize.' + namespace);
        }


        /* ------------------------------------------ */
        /**
        *   Sameheight -> AdjustHeights
        */
        function adjustHeights() {
            var view    = helper.returnView(),
                enabled = config.resizeEnabled;

            if (enabled.indexOf(view) === -1) return;

            var newHeight = 0;

            $targets.css({'height': 'auto'});

            $targets.each( function() {
                var $this = $(this),
                    thisH = $this.outerHeight();

                newHeight = thisH > newHeight ? thisH : newHeight;

            }).css({'height': newHeight});
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
