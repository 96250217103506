(function($, app) {
    'use strict';

    app.hero = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            blazy           = new app.blazy(),
            unique          = helper.returnUniqueHash(),
            namespace       = 'Hero-' + unique,
            prefix          = 'c-hero';

        var defaults        = {
            active: ['MD', 'LG', 'XL'],
            scrollBG: true,
            scrollInner: true,
            prefix: 'c-hero',
            entries: false,
            loaded: false,
            delay: 350
        };

        // Merge data-config with default settings
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);


        var $heroBG     = $('.' + config.prefix + '__background', $element),
            $heroInner  = $('.' + config.prefix + '__inner .container', $element),
            $entries    = $('.c-entry', $element);

        var currentview = helper.returnView(),
            timer       = null;


        /* ------------------------------------------ */
        /**
        *   Hero -> Initialize
        */
        function initialize() {
            blazy.get().revalidate();

            addEventListeners();

            if (config.entries) {
                reveal();
            }
        }


        /* ------------------------------------------ */
        /**
        *   Hero -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            $win
                .on('resize.' + namespace, handleResize)
                .trigger('resize.' + namespace);


            $win
                .on('scroll.' + namespace, reposition)
                .trigger('scroll.' + namespace);


            if (config.entries) {
                if ($entries.length > 0) {
                    $entries.on('mouseover', function(event) {
                        var $this   = $(this),
                            rel     = $this.attr('rel');

                        switchBackground(rel);
                    });

                    $element.on('mouseout', function(event) {
                        hideBackground();
                    });
                }
            }
        }


        /* --------------------------------------- */
        /**
        *   Hero -> handleResize()
        */
        function handleResize() {
            currentview = helper.returnView();
        }


        /* --------------------------------------- */
        /**
        *   Hero -> reposition()
        */
        function reposition() {
            if (config.active.indexOf(currentview) > -1) {

                var scrollTop   = $win.scrollTop(),
                    top         = 0,
                    bottom      = top + $element.outerHeight(),
                    bgPosition  = (scrollTop - top) * 0.360,
                    inPosition  = (scrollTop - top) * 0.540,
                    inHeight    = ($heroInner.outerHeight() /2) *-1,
                    scale, inOpacity;

                if (scrollTop >= top && scrollTop <= bottom) {

                    if (scrollTop <= 0) {
                        bgPosition = 0;
                        inPosition = 0;

                    } else {
                        bgPosition = bgPosition < 0 ? 0 : bgPosition;
                        inPosition = inPosition < 0 ? 0 : inPosition;
                    }

                    bgPosition = scrollTop < 1 ? 0 : bgPosition;
                    inPosition = scrollTop < 1 ? 0 : inPosition;

                } else {
                    bgPosition = 0;
                    inPosition = 0;
                }

                if ($heroBG.length > 0 && config.scrollBG) {
                    if (bgPosition >= -1) {
                        $heroBG.css({
                            'y': bgPosition + 'px'
                        });                            
                    }
                }

                if ($heroInner.length > 0 && config.scrollInner) {

                    inOpacity = (1 - ((inPosition /2) /100));
                    inOpacity = (inOpacity > 0 ? inOpacity: 0);

                    if (inPosition >= -1) {
                        $heroInner.css({
                            'y': (inPosition) + 'px',
                            'opacity':  inOpacity
                        });                            
                    }
                }
            }
        };


        function switchBackground(rel) {
            console.group('[Module] Hero -> fn.SwitchBackground');
            console.log('Rel: ', rel);

            if (typeof rel === 'undefined' || rel === null) return;

            var $target = $('[id="' + rel + '"]', $element),
                $siblings;

            console.log('Target ($): ', $target);

            if ($target.length > 0) {
                $siblings = $target.siblings('.' + config.prefix + '__background[id]', $element);

                console.log('Siblings ($): ', $siblings);

                $siblings.not($target).addClass(config.prefix + '__background--hidden');
                $target.removeClass(config.prefix + '__background--hidden');

                blazy.get().revalidate();
            }

            console.groupEnd();
        };

        function hideBackground() {
            console.group('[Module] Hero -> fn.HideBackground');
            console.groupEnd();

            $('.' + config.prefix + '__background[id]', $element).addClass(config.prefix + '__background--hidden');
        }


        /* --------------------------------------- */
        /**
        *   Hero -> reveal()
        */
        function reveal() {

            if (config.loaded) return;
            if (timer !== null) clearTimeout(timer);

            timer = setTimeout( function() {
                $('.' + config.prefix + '-wrp').addClass(config.prefix + '--loaded');

                config.loaded = true;
            }, config.delay);
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
