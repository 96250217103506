/**
*   GO Group
*   JS
*   version 2.0.1
*
*   @ Util
*/

/* ----------------------------------------------- */
/**
*   Index
*
*   [1]  Util
*
*   [2]  Util -> Date getWeek
*   [3]  Util -> Date getDayName
*   [4]  Util -> Date getDayNameShort
*   [5]  Util -> Date getMonthName
*   [6]  Util -> Date getMonthNameShort
*   [7]  Util -> Date Locale
*
*   [8]  Util -> XHR Progress
*/

/* ----------------------------------------------- */
/**
*	Util
*/

	var Util = (function() {

        var obj = {};


        /* --------------------------------------- */
        /**
        *   Util -> SVGFallback()
        *   Replaces '.svg' with '.png' if svg is not supported
        *
        *   @requires Modernizr
        */

        obj.SVGSupported = function() {
            return !!document.createElementNS && !!document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect;
        };


        /* --------------------------------------- */
        /**
        *   Util -> SVGFallback()
        *   Replaces '.svg' with '.png' if svg is not supported
        *
        *   @requires Modernizr
        */

        obj.SVGFallback = function() {
            var $svgs    = $('img[src$=".svg"]'),
                $svg     = null,
                src      = '';

            if (typeof Modernizr !== 'undefined') {            
                if (!Modernizr.svg) {
                    $svgs.each( function() {
                        $svg    = $(this);
                        src     = $svg.attr('src');

                        $svg.attr('src', src.replace('.svg', '.png'));
                    });
                }

            } else if (!obj.SVGSupported()) {
                $svgs.each( function() {
                    $svg    = $(this);
                    src     = $svg.attr('src');

                    $svg.attr('src', src.replace('.svg', '.png'));
                });                
            }
        };


        /* --------------------------------------- */
        /**
        *   Util -> DisableAutoComplete()
        *   Disables autocomplete for provided collection of elements
        *
        *   @param  {mixed}   $collection
        */

        obj.DisableAutoComplete = function($collection) {
            if (typeof $collection === 'undefined') {
                $collection = $('form, input, select, textarea');
            }

            $collection.attr({'autocomplete': 'off'});
        };


        /* --------------------------------------- */
        /**
        *   Util -> Capitalize()
        *   Capitalizes the provided string
        *   (uppercase first character)
        *
        *   @param  {string}    string
        *   @param  {string}    separator
        *
        *   @return {string}
        */

        obj.Capitalize = function(string, separator) {
            separator = typeof separator === 'undefined' ? ' ' : separator;

            var arr     = string.split(separator),
                temp    = '',
                string  = [];

            for (var i = 0; i < arr.length; i++) {
                temp = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                string.push(temp);
            }

            return string.join(separator);
        };


        /* --------------------------------------- */
        /**
        *   Util -> Sort()
        *
        *   @param  {string}   a
        *   @param  {string}   b
        */

        obj.Sort = function(a, b) {
            return (a < b) ? -1 : (a > b) ? 1 : 0;
        };


        /* --------------------------------------- */

        return obj;


    })();


/* ----------------------------------------------- */
/**
*   Util -> ucFirst
*   Capitalize first character in {string}
*   Extends String prototype
*
*   @return {string}
*/

    String.prototype.ucfirst = function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    };



/* ----------------------------------------------- */
/**
*   Util -> getWeek
*   Returns current weeknumber
*   Extends Date prototype
*
*   @return {number}
*/

    Date.prototype.getWeek = function() {
        var onejan      = new Date(this.getFullYear(), 0, 1),
            today       = new Date(this.getFullYear(), this.getMonth(), this.getDate()),
            dayOfYear   = ((today - onejan + 86400000) / 86400000);

        return Math.ceil(dayOfYear / 7);
    };


/* ----------------------------------------------- */
/**
*   Util -> getDayName
*   Returns name of the day
*   Extends Date prototype
*
*   @param  {string}    language
*
*   @return {string}
*/

    Date.prototype.getDayName = function(language) {
        language = language && (language in Date.locale) ? language : 'en';

        return Date.locale[language].days[this.getDate()];
    };


/* ----------------------------------------------- */
/**
*   Util -> getDayNameShort
*   Returns short name of the day
*   Extends Date prototype
*
*   @param  {string}    language
*
*   @return {string}
*/

    Date.prototype.getDayNameShort = function(language) {
        language = language && (language in Date.locale) ? language : 'en';

        return Date.locale[language].daysShort[this.getDate()];
    };


/* ----------------------------------------------- */
/**
*   Util -> getMonthName
*   Returns name of the month
*   Extends Date prototype
*
*   @param  {string}    language
*
*   @return {string}
*/

    Date.prototype.getMonthName = function(language) {
        language = language && (language in Date.locale) ? language : 'en';

        return Date.locale[language].months[this.getMonth()];
    };


/* ----------------------------------------------- */
/**
*   Util -> getMonthNameShort
*   Returns short name of the month
*   Extends Date prototype
*
*   @param  {string}    language
*
*   @return {string}
*/

    Date.prototype.getMonthNameShort = function(language) {
        language = language && (language in Date.locale) ? language : 'en';

        return Date.locale[language].monthsShort[this.getMonth()];
    };


/* ----------------------------------------------- */
/**
*   Util -> Locale
*   Defines the day/month names [EN/NL]
*   Extends Date prototype
*/

    Date.locale = {
        en: {
            months:         ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthsShort:    ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            days:           ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            daysShort:      ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        },
        nl: {
            months:         ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
            monthsShort:    ['jan', 'feb', 'maa', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
            days:           ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
            daysShort:      ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za']
        }
    };


/* ----------------------------------------------- */

function throttle(func, wait, options) {
  var context, args, result;
  var timeout = null;
  var previous = 0;
  if (!options) options = {};
  var later = function() {
    previous = options.leading === false ? 0 : Date.now();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return function() {
    var now = Date.now();
    if (!previous && options.leading === false) previous = now;
    var remaining = wait - (now - previous);
    context = this;
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
};