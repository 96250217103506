(function($, app) {
    'use strict';

    app.scrollto = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            unique          = helper.returnUniqueHash(),
            namespace       = 'ScrollTo-' + unique;

        var defaults        = {
            enabled: ['XXS', 'XS', 'SM', 'MD', 'LG', 'XL'],
            target: null,
            speed: 666,
            offset: 0
        };

        // Merge data-config with defaults
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);

        var $target;


        /* ------------------------------------------ */
        /**
        *   ScrollTo -> Initialize
        */
        function initialize() {
            if (config.target === null) return;

            $target = $(config.target);

            if ($target.length === 0) return;

            console.group('[Module] ScrollTo => fn.Initialize');
            console.log('Config: ', config);
            console.log('Target ($): ', $target);
            console.groupEnd();

            addEventListeners();
        }


        /* ------------------------------------------ */
        /**
        *   ScrollTo -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            $element.on('click.' + namespace, scrollToTarget);
        }

        /* ------------------------------------------ */
        /**
        *   ScrollTo -> ScrollToTarget
        */
        function scrollToTarget(event) {
            var view    = helper.returnView(),
                enabled = config.enabled;

            console.group('[Module] ScrollTo => fn.ScrollToTarget');
            console.log('Enabled: ', enabled.indexOf(view) === -1);

            if (enabled.indexOf(view) === -1) {
                console.groupEnd();
                return;
            }

            event.preventDefault();

            config.offset = $target.offset().top;
            config.offset -= 70;

            console.log('Config: ', config);
            console.log('Offset: ', config.offset);
            console.groupEnd();

            helper.scrollTo(config.offset, config.speed);
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
