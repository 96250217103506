(function($, app) {
    'use strict';

    app['employee-slider'] = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            blazy           = new app.blazy(),
            unique          = helper.returnUniqueHash(),
            namespace       = 'EmployeeSlider-' + unique,
            prefix          = 'c-employee-slider',
            slidePrefix     = 'c-employee';

        var defaults        = {
            speed: 750,
            loop: true,
            autoplay: 4200,
            direction: 'horizontal',
            effect: 'slide',
            infinite: true,
            focusOnSelect: false,
            draggable: true,
            swipeToSlide: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1720,
                    settings: { slidesToShow: 4 }
                },
                {
                    breakpoint: 1400,
                    settings: { slidesToShow: 4 }
                },
                {
                    breakpoint: 900,
                    settings: { slidesToShow: 3 }
                },
                {
                    breakpoint: 767,
                    settings: { slidesToShow: 2 }
                },
                {
                    breakpoint: 480,
                    settings: { slidesToShow: 1 }
                }
            ]
        };

        // Merge data-config with default settings
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);

        var $swiper,
            $prev = $('.' + prefix + '-button--prev'),
            $next = $('.' + prefix + '-button--next'),
            $pagination = $('.' + prefix + '-pagination'),
            $paginationList = $('.' + prefix + '-pagination-list', $pagination);


        /* ------------------------------------------ */
        /**
        *   Slider -> Initialize
        */
        function initialize() {
            console.group('[Module] EmployeeSlider => fn.Initialize');
            console.log('Config: ', config);

            var settings = $.extend({}, config, {
                prevArrow: '<div class="' + prefix + '__prev"><i class="c-icon c-icon--arrow-left"></i></div>',
                nextArrow: '<div class="' + prefix + '__next"><i class="c-icon c-icon--arrow-right"></i></div>',
                autoplay: config.autoplay,
                direction: config.direction,
                effect: config.effect,
                loop: config.loop,
                speed: config.speed
            });

            console.log('Settings: ', settings);
            console.groupEnd();

            $swiper = $element.slick(settings);

            $swiper.on('afterChange', function () {
                blazy.get().revalidate();
            });
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
