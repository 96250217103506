(function($, app) {
    'use strict';

    app.hamburger = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            namespace       = 'Hamburger',
            prefix          = 'c-hamburger',
            navPrefix       = 'c-navigation';

        var defaults        = {
            enabled: ['XXS', 'XS', 'SM', 'MD'],
            target: '.c-navigation'
        };

        // Merge data-config with default settings
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);

        var $list = $('.' + navPrefix + '__list');

        var propagationAdded = false;


        /* ------------------------------------------ */
        /**
        *   Hamburger -> Initialize
        */
        function initialize() {
            addEventListeners();
        }


        /* ------------------------------------------ */
        /**
        *   Hamburger -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            $element.on('click.' + namespace, handleClick);

            $body.on('click.' + namespace, handleClickOutside);
        }


        /* ------------------------------------------ */
        /**
        *   Hamburger -> HandleClick
        *   Event-handler for click-event
        */
        function handleClick(event) {
            event.preventDefault();
            event.stopPropagation();

            var view    = helper.returnView(),
                enabled = config.enabled;

            if (enabled.indexOf(view) === -1) return;

            if (config.target === null || config.target === '') return;

            var $target = $(config.target);

            if (!propagationAdded) {
                $target.on('click.' + namespace, function(event) {
                    event.stopPropagation();
                });
            }

            if ($element.hasClass(prefix + '--active')) {
                $target.removeClass(navPrefix + '--visible');
                $element.removeClass(prefix + '--active');

                helper.enableScroll();

            } else {
                $target.addClass(navPrefix + '--visible');
                $element.addClass(prefix + '--active');

                helper.disableScroll();
            }
        }


        /* ------------------------------------------ */
        /**
        *   Hamburger -> HandleClickOutside
        *   Event-handler for click-event (outside)
        */
        function handleClickOutside() {
            var view    = helper.returnView(),
                enabled = config.enabled;

            if (enabled.indexOf(view) === -1) return;

            if (config.target === null || config.target === '') return;

            var $target = $(config.target);

            $target.removeClass(navPrefix + '--visible');
            $element.removeClass(prefix + '--active');

            helper.enableScroll();
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
