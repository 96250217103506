(function($, app) {
    'use strict';

    app.reveal = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            blazy           = new app.blazy(),
            unique          = helper.returnUniqueHash(),
            namespace       = 'Reveal-' + unique,
            prefix          = 'c-reveal';

        var defaults        = {
            trigger: null,
            animation: 'fadeInUp',
            delay: 200
        };

        // Merge data-config with default settings
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);

        var $trigger = $element;

        var animClass,
            animDelay;


        /* ------------------------------------------ */
        /**
        *   Reveal -> Initialize
        */
        function initialize() {
            if (config.trigger !== null) {
                $trigger = $(config.trigger);
            }

            addHandler();
        }


        /* ------------------------------------------ */
        /**
        *   Reveal -> DddHandler
        *   Add the handler
        */
        function addHandler() {
            animClass = config.animation;
            animDelay = config.delay;

            $element.css({
                '-webkit-animation-delay':  animDelay,
                '-moz-animation-delay':     animDelay,
                '-ms-animation-delay':      animDelay,
                '-o-animation-delay':       animDelay,
                'animation-delay':          animDelay
            });

            $trigger.waypoint(function() {
                $element.addClass('animated').addClass(animClass);
            },{
                triggerOnce: true,
                offset: '90%'
            });
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
