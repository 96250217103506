(function($, app) {
    'use strict';

    app.callmeback = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            namespace       = 'CallMeBack',
            prefix          = 'c-form';

        var defaults        = {
            type: 'post',
            dataType: 'json',
            contentType: 'json'
        };

        // Merge data-config with defaults
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);

        var data    = {},
            errors  = false;

        var $inputs = $('.' + prefix + '__input', $element);

        var $closure = $element.prev('.' + prefix + '-closure');

        /* ------------------------------------------ */
        /**
        *   CallMeBack -> Initialize
        */
        function initialize() {
            addEventListeners();
        }


        /* ------------------------------------------ */
        /**
        *   CallMeBack -> Reset
        */
        function reset() {
            data    = {};
            errors  = false;
        }


        /* ------------------------------------------ */
        /**
        *   CallMeBack -> CheckForErrors
        */
        function checkForErrors() {
            errors = $element.find('.' + prefix + '__input--error').length > 0;
        }


        /* ------------------------------------------ */
        /**
        *   CallMeBack -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            $doc.on('cmbFormSubmit', function () {
                console.group('[Module] CallMeBack => fn.AddEventListeners');
                console.log('Config: ', config);
                console.log('CMB Settings: ', cmbSettings);

                $inputs.trigger('change');
                reset();
                checkForErrors();

                console.log('Data: ', data);
                console.groupEnd();

                if (errors) return;

                $inputs.each( function() {
                    var $this   = $(this),
                        name    = $this.attr('name'),
                        value   = $.trim($this.val());

                    data[name] = value;
                });

                $.ajax({
                    url: cmbSettings.ajaxUrl,
                    type: config.type,
                    dataType: config.dataType,
                    // contentType: config.contentType,
                    cache: false,
                    data: {
                        input: data,
                        action: cmbSettings.action
                    },
                    beforeSubmit: function(arr, $form, options) {
                        arr.push({
                            'name': 'nonce',
                            'value' : cmbSettings.nonce
                        });
                    },
                    success: function(responseText, statusText, xhr, $form) {
                        console.group('CMB Submit -> Success');
                        console.log('XHR: ', xhr);
                        console.log('Status: ', statusText);
                        console.log('Response: ', responseText);
                        console.log('Form: ', $form);
                        console.groupEnd();
                    },
                    error: function(response) {
                        console.group('CMB Submit -> Error');
                        console.log('Response: ', response);
                        console.groupEnd();
                    },
                    complete: function() {
                        $inputs.val('');

                        $element.transition({
                            x: '100%',
                            opacity: 0
                        }, 400, function() {
                            setTimeout(function() {
                                $element.hide();
                            }, 300);
                        });

                        $closure
                            .show()
                            .transition({
                                x: '0',
                                opacity: 1,
                                delay: 300
                            }, 400);
                    }
                });
            });
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});


// Google reCaptcha callback
function onCMBSubmit() {
    $doc.trigger('cmbFormSubmit');
}
