(function($, app) {
    'use strict';

    app.validate = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            unique          = helper.returnUniqueHash(),
            namespace       = 'Validate-' + unique,
            prefix          = 'c-form';

        var defaults        = {
            required:       true,
            matches:        false,
            minlength:      false,
            maxlength:      false,
            copy:           true,
            label:          'veld',
            type:           'text',
            name:           $element.attr('name'),
            id:             $element.attr('id'),
            msg: {
                invalid:    {type: 'invalid', string: 'Dit is geen geldig {field}'},
                empty:      {type: 'empty', string: 'Vul uw {field} in'},
                dontmatch:  {type: 'dontmatch', string: 'De ingevulde waarde komt niet overeen met {field}'},
                required:   {type: 'required', string: '{field} is een verplicht veld'},
                toolong:   	{type: 'toolong', string: '{field} overschrijdt het aantal toegestane tekens (max. {maxlength} verwacht)'},
                tooshort: 	{type: 'tooshort', string: '{field} is korter dan het minimaal aantal tekens (min. {maxlength} verwacht)'},
            }
        };

        // Merge data-config with default settings
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);


        var $form       = $element.parents('.' + prefix),
            $addon      = $('.' + prefix + '__addon[id="' + config.name + '"]', $form),
            $error      = $('.' + prefix + '-error[id="' + config.name + '"]', $form),
            $errorMsg   = $('.' + prefix + '-error__inner', $error),
            $siblings;

        var events = [],
            flowEvents = [];


        /* ------------------------------------------ */
        /**
        *   Validate -> Initialize
        */
        function initialize() {
        	// if (config.maxlength) {
	        // 	console.group('Validate');
	        // 	console.log('MaxLength: ', config.maxlength);
	        // 	console.groupEnd();
        	// }

            addEventListeners();
        }


        /* ------------------------------------------ */
        /**
        *   Validate -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            var triggers        = '',
                flowTriggers    = '';

            console.group('Validate field: ' + config.name);
            console.log('Type: ' + config.type);
            console.groupEnd();

            switch(config.type) {
                case 'text':
                case 'date':
                case 'email':
                case 'number':
                case 'iban':
                case 'phonenumber':
                case 'alphanumeric':
                case 'zipcode':
                case 'non-numeric':
                    events      = ['change', 'blur'];
                    flowEvents  = ['keyup', 'change', 'blur'];
                break;

                case 'radio':
                case 'checkbox':
                case 'select':
                    events = ['change', 'blur'];
                break;
            };


            for (var n = 0; n < events.length; n++) {
                triggers += ' ' + events[n] + '.' + namespace;
            }

            for (var n = 0; n < flowEvents.length; n++) {
                flowTriggers += ' ' + flowEvents[n] + '.' + namespace;
            }

            $element.on(triggers, function(event) {
                validate(event);

                if (flowEvents.length > 0) {
                    $element.off('.' + namespace);
                    $element.on(flowTriggers, validate);
                }
            });


            if (config.matches !== false) {
                var $match  = $('[name="' + config.matches + '"]', $form);

                $match.on(triggers, function(event) {
                    validate(event);

                    if (flowEvents !== '' && flowEvents.length > 0) {
                        $match.off('.' + namespace);
                        $match.on(flowTriggers, validate);
                    }
                });
            }



            if (!config.copy) {
                $element.on('cut copy paste', function(event) {
                    event.preventDefault();
                    return false;
                });
            }
        }


        /* ------------------------------------------ */
        /**
        *   Validate -> ShowError
        *   Show the $error 
        */
        function showError(msg) {
            $element.addClass(prefix + '__input--error');

            var message, label;

            if ($error.length > 0) {
                message = msg.string;
                label   = config.label;

                if (msg.type === 'required') label = label.ucfirst();

                if (config.maxlength && message.indexOf('{maxlength}') > -1) message = message.replace('{maxlength}', config.maxlength);
                if (config.minlength && message.indexOf('{minlength}') > -1) message = message.replace('{minlength}', config.minlength);

                message = message.replace('{field}', '<span class="c-form-error__field" >' + label + '</span>');

                $element
                    .removeClass(prefix + '__input--success')
                    .addClass(prefix + '__input--error');

                $addon
                    .removeClass(prefix + '__addon--success')
                    .addClass(prefix + '__addon--error');

                $errorMsg.html(message);
                $error.addClass(prefix + '-error--visible');
            }
        }


        /* ------------------------------------------ */
        /**
        *   Validate -> HideError
        *   Hide the $error 
        */
        function hideError() {
            $element.removeClass(prefix + '__input--error');
            
            if ($error.length > 0) {
                $element
                    .removeClass(prefix + '__input--error')
                    .addClass(prefix + '__input--success');

                $addon
                    .removeClass(prefix + '__addon--error')
                    .addClass(prefix + '__addon--success');

                $error.removeClass(prefix + '-error--visible');

                setTimeout(function() {
                    $errorMsg.html('');
                }, 300);
            }
        }


        /* ------------------------------------------ */
        /**
        *   Validate -> Validate
        *   Validate $element on [events]
        */
        function validate(event) {
            event.preventDefault();

            var value   = $.trim($element.val()),
                msg     = config.msg.empty,
                error   = false;

            // console.log('name: ', config.name);
            // console.log('type: ', config.type);
            // console.log('value: ', value);

            switch(config.type) {
                case ('radio'):
                case ('checkbox'):
                    $siblings = $('[name="' + config.name + '"]', $form);

                    msg = config.msg.required;
                    var checked = false;

                    $.each($siblings, function(i, el) {
                        var $this = $(this);

                        if ($this.is(':checked')) {
                            checked = true;
                        }
                    });

                    error = !checked;
                break;

                case ('alphanumeric'):
                    if (!Is.Set(value) && config.required) {
                        msg     = config.msg.empty;
                        error   = true;

                    } else if (Is.NotEmptyString(value) && !Is.AlphaNumeric(value)) {
                        msg     = config.msg.invalid;
                        error   = true;
                    }

                break;

                case ('alphanumeric'):
                    if (!Is.Set(value) && config.required) {
                        msg     = config.msg.empty;
                        error   = true;

                    } else if (Is.NotEmptyString(value) && !Is.AlphaNumeric(value)) {
                        msg     = config.msg.invalid;
                        error   = true;
                    }

                break;

                case ('zipcode'):
                    if (!Is.Set(value) && config.required) {
                        msg     = config.msg.empty;
                        error   = true;

                    } else if (Is.NotEmptyString(value) && !Is.ZipCode(value)) {
                        msg     = config.msg.invalid;
                        error   = true;
                    }

                break;

                case ('email'):

                    if (Is.EmptyString(value) && config.required) {
                        msg     = config.msg.empty;
                        error   = true;

                    } else if (Is.NotEmptyString(value) && !Is.ValidEmail(value)) {
                        msg     = config.msg.invalid;
                        error   = true;
                    }

                break;


                case ('number'):

                    value = parseInt(value);

                    if (Is.EmptyString(value) && config.required) {
                        msg     = config.msg.empty;
                        error   = true;

                    } else if (Is.NotEmptyString(value) && !Is.Number(value)) {
                        msg     = config.msg.invalid;
                        error   = true;
                    } else if (isNaN(value)) {
                        msg     = config.msg.invalid;
                        error   = true;
                    }

                break;

                case ('phonenumber'):

                    if (Is.EmptyString(value) && config.required) {
                        msg     = config.msg.empty;
                        error   = true;

                    } else if (Is.NotEmptyString(value) && !Is.ValidPhonenumber(value)) {
                        msg     = config.msg.invalid;
                        error   = true;
                    }

                break;

                case ('select'):

                    if (!Is.Set(value) && config.required) {
                        msg     = config.msg.empty;
                        error   = true;
                    }

                break;

                case ('iban'):

                    if (Is.EmptyString(value) && config.required) {
                        msg     = config.msg.empty;
                        error   = true;

                    } else if (Is.NotEmptyString(value) && !Is.ValidIBAN(value)) {
                        msg     = config.msg.invalid;
                        error   = true;
                    }

                break;
                case ('text'):

                    if (Is.EmptyString(value) && config.required) {
                        msg     = config.msg.empty;
                        error   = true;
                    }

                break;

                case ('non-numeric'):

                	$element.val(value.replace(/[0-9]/g, ''));

                    if (Is.EmptyString(value) && config.required) {
                        msg     = config.msg.empty;
                        error   = true;
                    }

                break;

                default:

                    if (!Is.Set(value) && config.required) {
                        msg     = config.msg.empty;
                        error   = true;
                    }
                break;
            }

            if (config.maxlength) {
            	// console.log('Too long? ', value.length > config.maxlength, value.length, config.maxlength);
            	if (value.length > config.maxlength) {
            		msg 	= config.msg.toolong;
            		error 	= true;
            	}
            }


            if (config.minlength) {
            	// console.log('Too short? ', value.length < config.minlength, value.length, config.minlength);
            	if (value.length < config.minlength) {
            		msg 	= config.msg.tooshort;
            		error 	= true;
            	}
            }


            if (config.matches !== false) {
                var $match  = $('[name="' + config.matches + '"]', $form),
                    match   = $.trim($match.val());

                // console.log('matches: ', config.matches);
                // console.log('matches -> $match: ', $match);
                // console.log('matches -> value: ', value);
                // console.log('matches -> match: ', match);

                if (!Is.TheSame(value, match)) {
                    msg     = config.msg.dontmatch;
                    error   = true;
                }
            }

            // console.log('msg: ', msg);
            // console.log('error: ', error);
            // console.log('$error: ', $error);
            // console.log('$form: ', $form);
            // console.log('');

            if (error) {
                showError(msg);
            } else {
                hideError();
            }
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});