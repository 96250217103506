/**
*   GO Group
*   JS
*   version 2.0.1
*
*   @ Main
*/

/* ----------------------------------------------- */

	$(window).on('load', function () {

		/* --------------------------------------- */
		/**
		*	Global Variables & caching $elements
		*/

		// -> Properties
		debug 	= false;
		devMode = true;
		time 		= 250;


		// -> Elements
		$win 	= $(window);
		$doc 	= $(document);
		$html 	= $('html');
		$body 	= $('body') || $(document.body);
		$page 	= $('html, body');

		/* --------------------------------------- */
		/**
		*	App -> App Definition
		*/

		// -> Register app
		window.app 		= window.app || {};
		window.blazy	= new app.blazy();


    // -> Init Client Utilities
    Client.init();


		// -> Check legacy
		app.legacy = false;
		if (
			(Client.getBrowser() === 'Microsoft Internet Explorer') &&
			(parseFloat(Client.getBrowserVersion()) <= 11.0)
		) {
			app.legacy = true;
			$html.addClass('is-ie');

		} else {
			$html.addClass('no-ie');
		}

		console.group('[Util] Client');
		console.log('OS: ', Client.getOS());
		console.log('Browser: ', Client.getBrowser());
		console.log('Version: ', Client.getBrowserVersion());
		console.log('Is Legacy: ', app.legacy);
		console.groupEnd();


		/* --------------------------------------- */
		/**
		*	App -> Instantiate
		*/

		app.instantiate = function (elem) {
			var $this   = $(elem);
			var module  = $this.attr('data-module');

			if(module === undefined) {
				throw 'Module not defined (use data-module="")';

			} else if (module in app) {
				if ($this.attr('data-initialized') === "true") return;
				new app[module](elem);
				$this.attr('data-initialized', true);

			} else {
				throw 'Module \'' + module + '\' not found';
			}
		};


		// -> Instantiate app
		$('[data-module]').each(function () {
			app.instantiate(this);
		});


		// Prevent navigation on parents
		$('.c-navigation__list__item--parent > a').on('click', function(event) {
			if ($(this).text() === 'Support') {
				event.preventDefault();
			}
		});


		// Prevent native title popup
		$('a[title]').on({
			mouseenter: function() {
				$(this).data('title', this.title).prop('title', '');
			},
			mouseleave: function() {
				$(this).prop('title', $(this).data('title'));
			}
		});


		/* --------------------------------------- */

	});


/* ----------------------------------------------- */
