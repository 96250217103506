(function($, app) {
    'use strict';

    app.gallery = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            namespace       = 'Gallery',
            prefix          = 'c-gallery',
            itemPrefix      = 'c-gallery-item';

        var defaults        = {
            resizeEnabled: ['SM', 'MD', 'LG', 'XL'],
            modifier: {
                'XXS': 1,
                'XS': 2,
                'SM': 2,
                'MD': 4,
                'LG': 4,
                'XL': 4
            },
            itemHeight: 0
        };

        // Merge data-config with defaults
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);

        var $items  = $('.' + itemPrefix, $element);


        /* ------------------------------------------ */
        /**
        *   Gallery -> Initialize
        */
        function initialize() {
            if (!$items.length) return;

            addEventListeners();
        }


        /* ------------------------------------------ */
        /**
        *   Gallery -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            $win
                .on('resize.' + namespace, resize)
                .trigger('resize.' + namespace);
        }


        /* ------------------------------------------ */
        /**
        *   Gallery -> Resize
        */
        function resize() {
            console.group('[Module] Gallery => fn.Resize');

            var view = helper.returnView();

            config.itemHeight = $element.outerWidth() / config.modifier[view];

            console.log('Viewport: ', view);
            console.log('Modifier: ', config.modifier[view]);
            console.log('Item height: ', config.itemHeight);

            $items.each( function(i, el) {
                var $this   = $(this);

                $this.css({'height': config.itemHeight});
            });

            console.groupEnd();
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
