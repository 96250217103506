(function($, app) {
    'use strict';

    app.backtotop = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            unique          = helper.returnUniqueHash(),
            namespace       = 'BackToTop-' + unique,
            prefix          = 'c-back-to-top';

        var defaults        = {
            someVariable: 'someValue'
        };

        // Merge data-config with default settings
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);


        /* ------------------------------------------ */
        /**
        *   BackToTop -> Initialize
        */
        function initialize() {
            addEventListeners();
        }


        /* ------------------------------------------ */
        /**
        *   BackToTop -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            $element.on('click.' + namespace, handleClick);
        }


        /* ------------------------------------------ */
        /**
        *   BackToTop -> HandleClick
        *   Event-handler for click-event
        */
        function handleClick(event) {
            event.preventDefault();
            event.stopPropagation();

            helper.scrollTo(0);
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
