$.fn.slideUpTransition = function() {
    return this.each(function() {
        var $el = $(this);
        $el.css({'max-height': 0, 'min-height': '0'});
        $el.addClass('c-height-transition--hidden');
    });
};

$.fn.slideDownTransition = function() {
    return this.each(function() {
        var $el     = $(this),
            height  = 0;

        $el.removeClass('c-height-transition--hidden');

        // Temporarily make visible to get the size
        $el.css({'min-height': 'none'});
        height = $el.outerHeight();

        if ($el.find('.c-navigation__list').length) {
            height = $el.find('.c-navigation__list').outerHeight();
        }

        if ($el.find('.c-faq-item__content-wrp').length) {
            height = $el.find('.c-faq-item__content-wrp').outerHeight();
        }

        if ($el.find('.wrp').length) {
            height = $el.find('.wrp').outerHeight();
        }

        // Reset to 0 then animate with small delay
        $el.css({'max-height': 0, 'min-height': 0});

        setTimeout(function() {
            $el.css({'max-height': (height *3), 'min-height': height});
        }, 1);
    });
};