/**
*   Acato
*   JS
*   version 0.0.1
*
*   @ Utilities -> Is
*/

/* ----------------------------------------------- */
/**
*   Index
*
*   [1]  Is -> Array
*   [3]  Is -> Boolean
*   [4]  Is -> Date
*   [5]  Is -> Even
*   [6]  Is -> Function
*   [7]  Is -> Image
*   [8]  Is -> Integer
*   [9]  Is -> Null
*   [10] Is -> Number
*   [11] Is -> Object
*   [12] Is -> Odd
*   [13] Is -> Primitive
*   [14] Is -> String
*   [15] Is -> EmptyString
*   [16] Is -> NotEmptyString
*   [17] Is -> Set
*   [18] Is -> TheSame
*   [19] Is -> Undefined
*   [20] Is -> WebUrl
*
*   [21] Is -> ValidEmail
*   [22] Is -> ValidPhonenumber
*   [23] Is -> ValidAddress
*   [24] Is -> ValidDate
*/

/* ----------------------------------------------- */
/**
*   Is
*/

    var Is = (function() {

        var obj = {};


        /* --------------------------------------- */
        /**
        *   Is -> Array()
        *   Usage: Is.Array()
        */

        obj.Array = function(data) {
            return Array.isArray(data);
        };


        /* --------------------------------------- */
        /**
        *   Is -> AlphaNumeric()
        *   Usage: Is.AlphaNumeric()
        */

        obj.AlphaNumeric = function(data) {
            var regex = new RegExp('^[a-zA-Z0-9]+$');

            return regex.test(data);
        };


        /* --------------------------------------- */
        /**
        *   Is -> Boolean()
        *   Usage: Is.Boolean()
        */

        obj.Boolean = function(data) {
            return data === false || data === true;
        };


        /* --------------------------------------- */
        /**
        *   Is -> Date()
        *   Usage: Is.Date()
        */

        obj.Date = function(data) {
            return  Object.prototype.toString.call(data) === '[object Date]' && !isNaN(data.getTime());
        };


        /* --------------------------------------- */
        /**
        *   Is -> Even()
        *   Usage: Is.Even()
        */

        obj.Even = function(data) {
            return number(data) && data %2 === 0;
        };


        /* --------------------------------------- */
        /**
        *   Is -> Function()
        *   Usage: Is.Function()
        */

        obj.Function = function(data) {
            return typeof data === 'function';
        };


        /* --------------------------------------- */
        /**
        *   Is -> Image()
        *   Usage: Is.Image()
        */

        obj.Image = function(data) {
          return data.match(/^data:image\/(bmp|gif|jpg|jpeg|png|svg)/gi);
        };


        /* --------------------------------------- */
        /**
        *   Is -> Integer()
        *   Usage: Is.Integer()
        */

        obj.Integer = function(data) {
            return number(data) && data %1 === 0;
        };


        /* --------------------------------------- */
        /**
        *   Is -> Null()
        *   Usage: Is.Null()
        */

        obj.Null = function(data) {
            return data === null;
        };


        /* --------------------------------------- */
        /**
        *   Is -> Number()
        *   Usage: Is.Number()
        */

        obj.Number = function(data) {
            return  typeof data === 'number' && isNaN(data) === false &&
                    data !== Number.POSITIVE_INFINITY &&
                    data !== Number.NEGATIVE_INFINITY;
        };


        /* --------------------------------------- */
        /**
        *   Is -> Object()
        *   Usage: Is.Object()
        */

        obj.Object = function(data) {
            return obj.Set(data) && Object.protoype.toString().call(data) === '[object Object]';
        };


        /* --------------------------------------- */
        /**
        *   Is -> Odd()
        *   Usage: Is.Odd()
        */

        obj.Odd = function(data) {
            return number(data) && !obj.Even(data);
        };


        /* --------------------------------------- */
        /**
        *   Is -> Primitive()
        *   Usage: Is.Primitive()
        */

        obj.Primitive = function(data) {  
            return (obj.Undefined(data) ||
                    obj.Null(data) ||
                    obj.Number(data) ||
                    obj.Boolean(data) ||
                    obj.String(data));
        };


        /* --------------------------------------- */
        /**
        *   Is -> String()
        *   Usage: Is.String()
        */

        obj.String = function(string) {
            return typeof string === 'string';
        };


        /* --------------------------------------- */
        /**
        *   Is -> EmptyString()
        *   Usage: Is.EmptyString()
        */

        obj.EmptyString = function(string) {
            return obj.String(string) && ($.trim(string).length === 0);
        };


        /* --------------------------------------- */
        /**
        *   Is -> NotEmptyString()
        *   Usage: Is.NotEmptyString()
        */

        obj.NotEmptyString = function(string) {
            return obj.String(string) && ($.trim(string).length > 0);
        };


        /* --------------------------------------- */
        /**
        *   Is -> Set()
        *   Usage: Is.Set()
        */

        obj.Set = function(data) {
            return !obj.Undefined(data) && !obj.Null(data);
        };


        /* --------------------------------------- */
        /**
        *   Is -> TheSame()
        *   Usage: Is.TheSame()
        */

        obj.TheSame = function(a, b) {
            return (a.length === b.length) && (a === b);
        };


        /* --------------------------------------- */
        /**
        *   Is -> Undefined()
        *   Usage: Is.Undefined()
        */

        obj.Undefined = function(data) {
            return (data === undefined || typeof data === 'undefined') && data === void 0;
        };


        /* --------------------------------------- */
        /**
        *   Is -> WebUrl()
        *   Usage: Is.WebUrl()
        */

        obj.WebUrl = function(url) {
            return obj.EmptyString(url) && /^(https?:)?\/\/([\w-\.~:@]+)(\/[\w-\.~\/\?#\[\]&\(\)\*\+,;=%]*)?$/.test(url);
        };


        /* --------------------------------------- */
        /**
        *   Is -> ValidAddress()
        *   Usage: Is.ValidAddress()
        */

        obj.ValidAddress = function(address) {
            return obj.NotEmptyString(address);
        };


        /* --------------------------------------- */
        /**
        *   Is -> Date()
        *   Usage: Is.Date()
        */

        obj.ValidDate = function(date) {
            return obj.Date(data);
        };


        /* --------------------------------------- */
        /**
        *   Is -> ValidEmail()
        *   Usage: Is.ValidEmail()
        */

        obj.ValidEmail = function(email) {
            var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);

            return pattern.test(email);
        };


        /* --------------------------------------- */
        /**
        *   Is -> ValidPhonenumber()
        *   Usage: Is.ValidPhonenumber()
        */

        obj.ValidPhonenumber = function(phonenumber) {
            var pattern = new RegExp(/^[\d\.\-]+$/);

            return pattern.test(phonenumber);
        };


        /* --------------------------------------- */

        return obj;


    })();


/* ----------------------------------------------- */
