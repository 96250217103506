/**
 *   GO Group
 *   JS
 *   version 2.0.1
 *
 *   @ Utilities -> Client
 */

/* ----------------------------------------------- */
/**
 *   Index
 *
 *   [1]  Client
 *
 */

/* ----------------------------------------------- */
/**
 *   Client
 */

var Client = (function() {
    var obj = {},
        info;

    /* --------------------------------------- */
    /**
     *   Client -> init()
     */

    obj.init = function() {
        obj.get();
    };

    /* --------------------------------------- */
    /**
     *   Client -> cookiesEnabled()
     */

    obj.cookiesEnabled = function() {
        return info.cookies;
    };

    /* --------------------------------------- */
    /**
     *   Client -> getBrowser()
     */

    obj.getBrowser = function() {
        return info.browser;
    };

    /* --------------------------------------- */
    /**
     *   Client -> getBrowserVersion()
     */

    obj.getBrowserVersion = function() {
        return info.browserVersion;
    };

    /* --------------------------------------- */
    /**
     *   Client -> getOS()
     */

    obj.getOS = function() {
        return info.os;
    };

    /* --------------------------------------- */
    /**
     *   Client -> getOSVersion()
     */

    obj.getOSVersion = function() {
        return info.osVersion;
    };

    /* --------------------------------------- */
    /**
     *   Client -> onMobile()
     */

    obj.onMobile = function() {
        return info.mobile;
    };

    /* --------------------------------------- */
    /**
     *   Client -> onTablet()
     */

    obj.onTablet = function() {
        return info.tablet;
    };

    /* --------------------------------------- */
    /**
     *   Client -> onDevice()
     */

    obj.onDevice = function() {
        return obj.onMobile() && obj.onTablet();
    };

    /* --------------------------------------- */
    /**
     *   Client -> get()
     *   Returns an array containing client information
     *
     *   @return Array
     *   {
     *       browser:            {string}      'current browser'
     *       browserVersion:     {string}      'current browser-version'
     *       cookies:            {boolean}     'cookies enabled'
     *       mobile:             {boolean}     'mobile phone'
     *       os:                 {string}      'current OS'
     *       osVersion:          {string}      'current OS-version'
     *       tablet:             {boolean}     'tablet device'
     *   }
     */

    obj.get = function() {
        var unknown = 'unknown';

        //  browser
        var nVer = navigator.appVersion,
            nAgt = navigator.userAgent,
            browser = navigator.appName,
            version = '' + parseFloat(navigator.appVersion),
            majorVersion = parseInt(navigator.appVersion, 10),
            nameOffset,
            verOffset,
            ix;

        // Opera
        if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
            browser = 'Opera';
            version = nAgt.substring(verOffset + 6);

            if ((verOffset = nAgt.indexOf('Version')) !== -1) {
                version = nAgt.substring(verOffset + 8);
            }
        }

        // MSIE (Microsoft Internet Explorer 10-)
        else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
            browser = 'Microsoft Internet Explorer';
            version = nAgt.substring(verOffset + 5);
        }

        // Chrome
        else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
            browser = 'Chrome';
            version = nAgt.substring(verOffset + 7);
        }

        // Safari
        else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
            browser = 'Safari';
            version = nAgt.substring(verOffset + 7);

            if ((verOffset = nAgt.indexOf('Version')) !== -1) {
                version = nAgt.substring(verOffset + 8);
            }
        }

        // Firefox
        else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
            browser = 'Firefox';
            version = nAgt.substring(verOffset + 8);
        }

        // MSIE 11+ (Microsoft Internet Explorer 11+)
        else if (nAgt.indexOf('Trident/') !== -1) {
            browser = 'Microsoft Internet Explorer';
            version = nAgt.substring(nAgt.indexOf('rv:') + 3);
        }

        // Other browsers
        else if (
            (nameOffset = nAgt.lastIndexOf(' ') + 1) <
            (verOffset = nAgt.lastIndexOf('/'))
        ) {
            browser = nAgt.substring(nameOffset, verOffset);
            version = nAgt.substring(verOffset + 1);
            if (browser.toLowerCase() === browser.toUpperCase()) {
                browser = navigator.appName;
            }
        }

        // trim the version string
        if ((ix = version.indexOf(';')) !== -1)
            version = version.substring(0, ix);
        if ((ix = version.indexOf(' ')) !== -1)
            version = version.substring(0, ix);
        if ((ix = version.indexOf(')')) !== -1)
            version = version.substring(0, ix);

        majorVersion = parseInt('' + version, 10);
        if (isNaN(majorVersion)) {
            version = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }

        // mobile phone
        var mobile = /Mobile|mini|Fennec|Android|iP(hone|od)/.test(nVer);

        // tablet device
        var tablet = /iPad/.test(nVer);

        // cookies
        var cookieEnabled = navigator.cookieEnabled ? true : false;

        if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
            document.cookie = 'testcookie';
            cookieEnabled =
                document.cookie.indexOf('testcookie') !== -1 ? true : false;
        }

        // OS (Operating System)
        var os = unknown,
            clientStrings = [
                { s: 'Windows 3.11', r: /Win16/ },
                { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
                { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
                { s: 'Windows 98', r: /(Windows 98|Win98)/ },
                { s: 'Windows CE', r: /Windows CE/ },
                { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
                { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
                { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
                { s: 'Windows Vista', r: /Windows NT 6.0/ },
                { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
                { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
                { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
                {
                    s: 'Windows NT 4.0',
                    r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/,
                },
                { s: 'Windows ME', r: /Windows ME/ },
                { s: 'Android', r: /Android/ },
                { s: 'Open BSD', r: /OpenBSD/ },
                { s: 'Sun OS', r: /SunOS/ },
                { s: 'Linux', r: /(Linux|X11)/ },
                { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
                { s: 'Mac OS X', r: /Mac OS X/ },
                { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
                { s: 'QNX', r: /QNX/ },
                { s: 'UNIX', r: /UNIX/ },
                { s: 'BeOS', r: /BeOS/ },
                { s: 'OS/2', r: /OS\/2/ },
                {
                    s: 'Search Bot',
                    r: /(nuhk|Googlebot|Bing Bot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
                },
            ];

        for (var id in clientStrings) {
            var cs = clientStrings[id];
            if (cs.r.test(nAgt)) {
                os = cs.s;
                break;
            }
        }

        // OS-version (Operating System Version)
        var osVersion = unknown;

        if (/Windows/.test(os)) {
            osVersion = /Windows (.*)/.exec(os)[1];
            os = 'Windows';
        }

        switch (os) {
            case 'Mac OS X':
                osVersion = 0;
                break;

            case 'Android':
                osVersion = 0;
                break;

            case 'iOS':
                osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
                osVersion =
                    osVersion[1] +
                    '.' +
                    osVersion[2] +
                    '.' +
                    (osVersion[3] | 0);
                break;
        }

        return (info = {
            browser: browser,
            browserVersion: version,
            cookies: cookieEnabled,
            mobile: mobile,
            os: os,
            osVersion: osVersion,
            tablet: tablet,
        });
    };

    /* --------------------------------------- */

    return obj;
})();

/* ----------------------------------------------- */
