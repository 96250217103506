(function($, app) {
    'use strict';

    app.navigation = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            unique          = helper.returnUniqueHash(),
            namespace       = 'Navigation-' + unique,
            prefix          = 'c-navigation';

        var defaults        = {
            enabled: ['MD', 'LG', 'XL'],
            variable: 'value'
        };

        // Merge data-config with defaults
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);

        var $navList    = $('.' + prefix + '__list', $element),
            $navItems   = $('.' + prefix + '__list__item--parent', $navList),
            $navLinks   = $('.' + prefix + '__list__link', $navItems);

        var leaveTimer  = null;


        /* ------------------------------------------ */
        /**
        *   Navigation -> Initialize
        */
        function initialize() {

            if ($navLinks.length > 0) {
                addEventListeners();
            }
        }


        /* ------------------------------------------ */
        /**
        *   Navigation -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            $navItems
                .on('mouseenter.' + namespace, handleMouseEnter)
                .on('mouseleave.' + namespace, handleMouseLeave);
        }


        /* ------------------------------------------ */
        /**
        *   Navigation -> HandleMouseEnter
        */
        function handleMouseEnter(event) {

        }


        /* ------------------------------------------ */
        /**
        *   Navigation -> HandleMouseLeave
        */
        function handleMouseLeave(event) {
            if (leaveTimer !== null) clearTimeout(leaveTimer);

            leaveTimer = setTimeout(function() {

            }, 100);
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
