(function($, app) {
    'use strict';

    app.header = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            namespace       = 'Header',
            prefix          = 'c-header';

        var defaults        = {
            enabled: ['SM', 'MD', 'LG', 'XL'],
            target: '.c-header',
            offset: 60,
            elTop: 0,
            winTop: 0,
            stuck: false,
            hero: $('.c-hero').length > 0,
            entries: $('.c-entries-wrp').length > 0
        };

        // Merge data-config with default settings
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);


        /* ------------------------------------------ */
        /**
        *   Header -> Initialize
        */
        function initialize() {
            console.group('[Module] Header => fn.Initialize');
            console.log('Config: ', config);
            console.groupEnd();

            if (config.target === null || config.target === '') return;

            var $target = $(config.target);

            if (config.hero || config.entries) {
                config.elTop = parseInt($target.css('top'), 10) + parseInt($target.outerHeight(), 10);
            }

            addEventListeners();
        }


        /* ------------------------------------------ */
        /**
        *   Header -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            $win
                .on('scroll.' + namespace, handleScroll)
                .trigger('scroll.' + namespace);
        }


        /* ------------------------------------------ */
        /**
        *   Header -> HandleClickOutside
        *   Event-handler for click-event (outside)
        */
        function handleScroll() {
            var $target = $(config.target);

            config.winTop = $win.scrollTop();

            // console.group('Module => Header');
            // console.log('winTop: ', config.winTop);
            // console.log('offset: ', config.offset);
            // console.log('calc: ', (config.offset + config.elTop));
            // console.groupEnd();

            if (config.winTop >= (config.offset + config.elTop)) {
                $target.addClass(prefix + '--stuck');

            } else {
                $target.removeClass(prefix + '--stuck');
            }
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
