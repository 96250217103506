(function($, app) {
    'use strict';

    app.expand = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            unique          = helper.returnUniqueHash(),
            namespace       = 'Expand-' + unique,
            prefix          = 'c-expand';

        var defaults        = {
            expanded: false,
            trigger: '.js-c-expand-trigger',
            target: '.js-c-expand-target'
        };

        // Merge data-config with defaults
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);

        var $trigger    = $(config.trigger, $element).eq(0),
            $target     = $(config.target, $element).eq(0);


        /* ------------------------------------------ */
        /**
        *   expand -> Initialize
        */
        function initialize() {
            if ($trigger.length === 0) return;
            if ($target.length === 0) return;

            if (config.expanded) {
                $trigger.addClass(prefix + '--expanded');
                $target.show();

            } else {
                $trigger.removeClass(prefix + '--expanded');
                $target.hide();
            }

            addEventListeners();
        }


        /* ------------------------------------------ */
        /**
        *   expand -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            $trigger.on('click.' + namespace, handleClick);
        }

        function handleClick(event) {
            event.preventDefault();
            event.stopPropagation();

            $trigger.toggleClass(prefix + '--expanded');
            $target.slideToggle(300);
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
