(function($, app) {
    'use strict';

    app.gmap = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            namespace       = 'Gmap',
            prefix          = 'c-gmap';

        var defaults = {
            marker: {
                image: {
                    png: '/dist/assets/icons/map-marker.png',
                    svg: '/dist/assets/icons/map-marker.svg',
                    obj: null
                },
                shadow: {
                    png: '/dist/assets/icons/map-marker-shadow.png',
                    obj: null
                },
                shape: null
            },
            styles:     null,
            title:      'GO Group Audiovisueel',
            url:        'https://www.google.nl/maps/place/Go+Rental%2FGo+Sales/@52.0211838,5.1475707,16z/data=!4m5!3m4!1s0x0:0x1425dedc4546b170!8m2!3d52.0211838!4d5.1475707',
            target: '_blank',
            zoom:   16,
            position: {
                lat: 52.0211838,
                lng: 5.1475707
            }
        };

        // Merge data-config with default settings
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);


        var $map = $element,
            map;


        /* ------------------------------------------ */
        /**
        *   Gmap -> Initialize
        */
        function initialize() {
            addEventListeners();
        }


        /* ------------------------------------------ */
        /**
        *   Gmap -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            // google.maps.event.addDomListener(window, 'load', function() {
                defineStyles();
                defineMarker();

                setTimeout(function() {
                    createMap();
                }, 1500);
            // });
        }


        /* --------------------------------------- */
        /**
        *   Gmap -> defineStyles()
        */

        function defineStyles() {
            config.styles = [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"saturation":42},{"color":"#000000"},{"lightness":42}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#000000"},{"lightness":16}]},{"featureType":"all","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#000000"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#000000"},{"lightness":17},{"weight":1.2}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":20}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":21}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#000000"},{"lightness":27}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#000000"},{"lightness":27},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":27}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":27}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":19}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":17}]}];
        }


        /* --------------------------------------- */
        /**
        *   Gmap -> defineMarker()
        */

        function defineMarker() {
            config.marker.image.obj = new google.maps.MarkerImage(
                config.path + config.marker.image[(app.legacy ? 'png' : 'svg')],
                new google.maps.Size(80,80),
                new google.maps.Point(0,0),
                new google.maps.Point(40,80)
            );

            config.marker.shadow.obj = new google.maps.MarkerImage(
                config.path + config.marker.shadow.png,
                new google.maps.Size(124,80),
                new google.maps.Point(0,0),
                new google.maps.Point(40,80)
            );

            config.marker.shape = {
                coord: [79,0,79,1,79,2,79,3,79,4,79,5,79,6,79,7,79,8,79,9,79,10,79,11,79,12,79,13,79,14,79,15,79,16,79,17,79,18,79,19,79,20,79,21,79,22,79,23,79,24,79,25,79,26,79,27,79,28,79,29,79,30,79,31,79,32,79,33,79,34,79,35,79,36,79,37,79,38,79,39,79,40,79,41,79,42,79,43,79,44,79,45,79,46,79,47,79,48,79,49,79,50,79,51,79,52,79,53,79,54,79,55,79,56,79,57,79,58,79,59,79,60,79,61,79,62,79,63,79,64,79,65,79,66,79,67,79,68,47,69,46,70,46,71,45,72,44,73,44,74,43,75,42,76,42,77,41,78,41,79,39,79,39,78,38,77,38,76,37,75,36,74,36,73,35,72,34,71,34,70,33,69,0,68,0,67,0,66,0,65,0,64,0,63,0,62,0,61,0,60,0,59,0,58,0,57,0,56,0,55,0,54,0,53,0,52,0,51,0,50,0,49,0,48,0,47,0,46,0,45,0,44,0,43,0,42,0,41,0,40,0,39,0,38,0,37,0,36,0,35,0,34,0,33,0,32,0,31,0,30,0,29,0,28,0,27,0,26,0,25,0,24,0,23,0,22,0,21,0,20,0,19,0,18,0,17,0,16,0,15,0,14,0,13,0,12,0,11,0,10,0,9,0,8,0,7,0,6,0,5,0,4,0,3,0,2,0,1,0,0,79,0],
                type: 'poly'
            };
        }


        /* --------------------------------------- */
        /**
        *   Gmap -> createMap()
        */

        function createMap() {
            var location = new google.maps.LatLng(config.position.lat, config.position.lng);

            console.group('[Module] Gmap => fn.CreateMap');
            console.log('Config: ', config);
            console.log('Location: ', location);
            console.groupEnd();

            var options = {
                center:             location,
                styles:             config.styles,
                zoom:               config.zoom,
                url:                config.url,
                draggable:          true,
                scrollwheel:        false,
                mapTypeControl:     false,
                scaleControl:       false,
                zoomControl:        false,
                panControl:         false,
                streetViewControl:  false,
                mapTypeId:          google.maps.MapTypeId.ROADMAP
            };
            map = new google.maps.Map($map[0], options);

            addMarker();
        }


        /* --------------------------------------- */
        /**
        *   Gmap -> addMarker()
        */

        function addMarker() {
            var location    = new google.maps.LatLng(config.position.lat, config.position.lng),
                title       = config.title;

            var $marker = new google.maps.Marker({
                position:   map.getCenter(),
                map:        map,
                title:      title,
                animation:  google.maps.Animation.DROP,
                icon:       config.marker.image.obj,
                shadow:     config.marker.shadow.obj,
                shape:      config.marker.shape
            });

            google.maps.event.addListener($marker, 'click', function(event) {
                window.open(config.url, config.target);
            });
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
