/*global window,jQuery*/

(function ($, app) {
    "use strict";
    app.helper = function () {


        /* ------------------------------------------ */
        /**
        *   Helper -> New Instance
        */
        if (app.helper.prototype._instance) {
            return app.helper.prototype._instance;
        }

        app.helper.prototype._instance = this;


        /* ------------------------------------------ */
        /**
        *   Helper -> Re-Instantiate
        */
        this.reInstantiate = function($collection) {
            if (typeof $collection !== 'undefined' && $collection.length > 0) {
                $collection.each(function () {
                    app.instantiate(this);
                });
            }
        };


        /* ------------------------------------------ */
        /**
        *   Helper -> Return View
        *   @return     String      ("XXS", "XS", "SM", "MD", "LG" or "XL")
        */
        this.returnView = function() {
            var view = window.getComputedStyle(document.body,":after").getPropertyValue("content").split('"').join('').split('\'').join('');
            view.replace(/^[A-Za-z]+$/, '').toUpperCase();

            return view;
        };


        /* ------------------------------------------ */
        /**
        *   Helper -> Return View
        *   Return an array of all available viewports
        *   @return     Array      ["XXS", "XS", "SM", "MD", "LG", "XL"]
        */
        this.returnAllViewports = function() {
            return ['XXS', 'XS', 'SM', 'MD', 'LG', 'XL'];
        };


        /* ------------------------------------------ */
        /**
        *   Helper -> Return Unique Hash
        *   Return an unique hash (string)
        */
        this.returnUniqueHash = function() {
            return Math.random().toString(36).substr(2, 5);
        };


        /* ------------------------------------------ */
        /**
        *   Helper -> ToggleScroll
        *   Disable/Enable scrolling in specified $element
        *
        *   @param  $element    Element
        *   @param  flag        Boolean
        */
        this.toggleScroll = function($element, flag) {
            if ((typeof flag === 'undefined') || (typeof flag !== 'boolean')) return;

            // Check if $element is actually set
            // otherwise use default $('html, body')
            $element    = (typeof $element !== 'undefined') && ($element.length > 0) ? $element : $('html, body');

            $element[flag ? 'addClass' : 'removeClass']('h-scroll--disabled');
        };


        /* ------------------------------------------ */
        /**
        *   Helper -> EnableScroll
        *   Enable scrolling in specified $element
        *
        *   @param  $element    Element
        */
        this.enableScroll = function($element) {
            this.toggleScroll($element, false);
        };


        /* ------------------------------------------ */
        /**
        *   Helper -> DisableScroll
        *   Disable scrolling in specified $element
        *
        *   @param  $element    Element
        */
        this.disableScroll = function($element) {
            this.toggleScroll($element, true);
        };


        /* ------------------------------------------ */
        /**
        *   Helper -> ScrollTo
        *   Scroll the viewport to a given point
        *
        *   @param     target       Mixed
        *   @param     duration     Integer/Number
        */
        this.scrollTo = function(target, duration, $scroller) {
            var $page = $('html, body');


            // Check if $scroller is actually set
            // otherwise use default '$page'
            $scroller    = (typeof $scroller !== 'undefined') && ($scroller.length > 0) ? $scroller : $page;


            // Check if duration is actually set
            // otherwise use default '800ms'
            duration    = (typeof duration !== 'undefined') && (duration !== null) ? duration : 800;


            // Check if target is actually set
            // otherwise use default '0'
            target      = (typeof target !== 'undefined') && (target !== null) ? target : 0;


            // Bind to user-events
            $page.on('scroll.Helper mousedown.Helper wheel.Helper DOMMouseScroll.Helper mousewheel.Helper keyup.Helper touchmove.Helper', function() {
                    $scroller.stop();
            });

            // Scroll the $scroller,
            // and unbind the user-events
            $scroller
                .stop()
                .animate({scrollTop: target}, duration, function() {
                    $page.off('scroll.Helper mousedown.Helper wheel.Helper DOMMouseScroll.Helper mousewheel.Helper keyup.Helper touchmove.Helper');
                });
        };


        /* ------------------------------------------ */
        /**
        *   Helper -> callbackOnKey
        *   Execute a callback when user presses the provided key
        *   Default key: 27 (Escape)
        *
        *   @param      callback        Function
        *   @param      key             Integer/Number
        *   @param      namespace       String
        */
        this.callbackOnKey = function(callback, key, namespace) {
            if (!$.isFunction(callback)) return;

            key       = (typeof key !== 'undefined') ? key : 27;
            namespace = (typeof namespace !== 'undefined') ? namespace : (Math.random().toString(36).substr(2, 5));

            $(document).on('keyup.' + namespace, function(event) {
                var keyCode = event.which || event.keyCode;

                if (keyCode === key) {
                    callback();
                }
            });
        };


        /* ------------------------------------------ */
        /**
        *   Helper -> requestAnimationFrame
        */
        this.requestAnimationFrame = function() {
            (function () {
                return  window.requestAnimationFrame        ||
                        window.webkitRequestAnimationFrame  ||
                        window.mozRequestAnimationFrame     ||
                        function (callback) {
                            window.setTimeout(callback, 1000 / 60);
                        };
            }());
        };


    };

} (jQuery, window.app = window.app || {}));
