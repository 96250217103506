(function($, app) {
    'use strict';

    app.slider = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            blazy           = new app.blazy(),
            unique          = helper.returnUniqueHash(),
            namespace       = 'Slider-' + unique,
            prefix          = 'c-slider',
            slidePrefix     = 'c-slide';

        var defaults        = {
            speed: 750,
            loop: true,
            autoplay: 4200,
            direction: 'horizontal',
            effect: 'slide'
        };

        // Merge data-config with default settings
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);

        var $swiper,
            $prev = $('.' + prefix + '-button--prev'),
            $next = $('.' + prefix + '-button--next'),
            $pagination = $('.' + prefix + '-pagination'),
            $paginationList = $('.' + prefix + '-pagination-list', $pagination);


        /* ------------------------------------------ */
        /**
        *   Slider -> Initialize
        */
        function initialize() {
            console.group('[Module] Slider => fn.Initialize');
            console.log('Config: ', config);
            console.groupEnd();

            $swiper = new Swiper ($element, {
                autoplayDisableOnInteraction: false,
                autoplay: config.autoplay,
                direction: config.direction,
                effect: config.effect,
                loop: config.loop,
                speed: config.speed,

                containerModifierClass: prefix + '--',
                wrapperClass: prefix + '__inner',
                slideClass: slidePrefix,

                prevButton: $prev,
                nextButton: $next,

                pagination: $paginationList,
                paginationClickable: true,
                paginationElement: 'li',
                paginationBulletRender: function (swiper, index, className) {
                    return '<li class="' + prefix + '-pagination-list__item">' + (index + 1) + '</li>';
                },
                bulletClass: prefix + '-pagination-list__item',
                bulletActiveClass: prefix + '-pagination-list__item--active'
            });

            $swiper.on('onSlideChangeEnd', function () {
                blazy.get().revalidate();
            });
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
