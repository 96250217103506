/*global window,jQuery*/

(function($, app) {
    'use strict';

    app.toggleElement = function (element) {

        // Turn the target into an jQuery element
        var $element    = $(element);

        // Gather the data & invoke a new helper
        var config      = $element.data('config'),
            helper      = new app.helper(),
            unique      = helper.returnUniqueHash(),
            namespace   = 'ToggleElement-' + unique,
            prefix      = 'c-toggle-element';


        // Define the default settings
        var defaults    = {
            type: null,
            target: null,
            showDefault: false
        };

        // Merge data-config with default settings
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);


        // Define the neccesary elements
        var $show   = $('> .js-' + prefix + '__show', $element),
            $hide   = $('> .js-' + prefix + '__hide', $element),
            $target = $(config.target);


        /* ------------------------------------------ */
        /**
        *   ToggleElement -> Initialize
        */
        function initialize() {
            if ($target.length > 0) {
                toggleElement(false);
            }

            addEventListeners();
        };


        /* ------------------------------------------ */
        /**
        *   ToggleElement -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            var event,
                $trigger;

            if (config.type === null) {
                $show.on('click.' + namespace, handleClickShow);
                $hide.on('click.' + namespace, handleClickHide);

            } else if (config.type === 'link') {
                $element.on('click.' + namespace, handleEvent);

            } else {

                switch(config.type) {
                    case 'radio':
                    case 'checkbox':
                        event = 'change';
                        $trigger = $('[name="' + $element.attr('name') + '"]');
                    break;

                    case 'link':
                    default:
                        event       = 'click';
                        $trigger    = $element;
                    break;
                }

                $trigger.on(event + '.' + namespace, handleEvent);
            }
        };


        /* ------------------------------------------ */
        /**
        *   ToggleElement -> HandleEvent
        *   Event-handler for event on show-link
        *
        *   @param      event
        */
        function handleEvent() {
            if (config.type === 'radio' || config.type === 'checkbox') {
                toggleElement($element.is(':checked'));
            } else {
                var $target = $(config.target);

                toggleElement($target.is(':hidden'));
            }
        };


        /* ------------------------------------------ */
        /**
        *   ToggleElement -> HandleClickShow
        *   Event-handler for click-event on show-link
        *
        *   @param      event
        */
        function handleClickShow(event) {
            event.preventDefault();
            event.stopPropagation();

            $element.addClass(prefix + '--collapsed');
        };


        /* ------------------------------------------ */
        /**
        *   ToggleElement -> HandleClickHide
        *   Event-handler for click-event on hide-link
        *
        *   @param      event
        */
        function handleClickHide(event) {
            event.preventDefault();
            event.stopPropagation();

            $element.removeClass(prefix + '--collapsed');
        };


        /* ------------------------------------------ */
        /**
        *   ToggleElement -> ToggleElement
        *   Show/hide the target
        */
        function toggleElement(boolean) {
            var $target = $(config.target);

            if (boolean) {
                $target.slideDownTransition();
            } else {
                $target.slideUpTransition();
            }
        };


        initialize();

    };
})(jQuery, window.app = window.app || {});
