/*global window,jQuery,Blazy*/

(function($, app) {
    'use strict';

    app.blazy = function () {

        // Only 1 configuration >> Singleton
        if (app.blazy.prototype._instance) {
            return app.blazy.prototype._instance;
        }

        app.blazy.prototype._instance = this;

        var instance = new Blazy();
        var resizeTimer;


        // Add event listeners
        function addEventListeners() {
            $(window).on('resize', onResize).trigger('resize');
        }


        // Handle window resize
        // throttle event and revalidate our blazy instance
        function onResize() {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function () {
                instance.revalidate();
            }, 100);
        }

        addEventListeners();

        this.get = function() {
            return instance;
        };

    };
})(jQuery, window.app = window.app || {});