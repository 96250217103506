(function($, app) {
    'use strict';

    app.products = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            blazy           = new app.blazy(),
            unique          = helper.returnUniqueHash(),
            namespace       = 'Products-' + unique,
            prefix          = 'c-product';

        var defaults        = {
            type: 'post',
            dataType: 'json',
            contentType: 'json',
            filters: {
                parent: null,
                categories: [],
                brands: []
            },
            count: {
                from: 0,
                to: 0,
                speed: 1000,
                refreshInterval: 50
            },
            results: []
        };

        // Merge data-config with defaults
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);

        var $filterWrp  = $('.' + prefix + '-filters', $element),
            $filters    = $('.' + prefix + '__filter', $filterWrp);

        var $reset      = $('.js-c-reset-filters', $element);

        var $wrp        = $('.js-' + prefix + 's-wrp', $element),
            $num        = $('.js-' + prefix + 's-num', $element);

        var $loader     = $('.js-' + prefix + 's-loader', $element);

        var template = Handlebars.compile(document.getElementById('template-product-item').innerHTML);

        var call    = null,
            timer   = null;

        /* ------------------------------------------ */
        /**
        *   Products -> Initialize
        */
        function initialize() {
            // getSelectedFilters();
            handleFilterChange();
            addEventListeners();
        }


        /* ------------------------------------------ */
        /**
        *   Products -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            $filters.on('change.' + namespace, handleFilterChange);

            $reset.on('click.' + namespace, clearFilters);
        }


        /* ------------------------------------------ */
        /**
        *   Products -> ClearFilters
        */
        function clearFilters() {
            $filters.each( function() {
                var $this = $(this);

                $this.prop('checked', false);
            });

            handleFilterChange();
        }

        /* ------------------------------------------ */
        /**
        *   Products -> ResetFilters
        */
        function resetFilters() {
            config.filters.categories = [];
            config.filters.brands = [];
            config.result = [];
        }


        /* ------------------------------------------ */
        /**
        *   Products -> getProducts
        */
        function getProducts() {
            if (timer !== null) clearTimeout(timer);
            if (call !== null) call.abort();

            $loader.addClass('c-loader--visible');

            $wrp
                .stop(true, true)
                .transit({
                    opacity: 0
                }, 300, function() {

                    $('[data-module="sameheight"]', $wrp)
                        .attr('data-initialized', false)
                        .html('');

                    config.count.from = config.count.to;

                    timer = setTimeout(function() {

                        call = $.ajax({
                            url: productSettings.ajaxUrl,
                            type: config.type,
                            dataType: config.dataType,
                            // contentType: config.contentType,
                            cache: false,
                            data: {
                                categories: config.filters.categories,
                                brands: config.filters.brands,
                                action: productSettings.action
                            },
                            beforeSubmit: function(arr, $form, options) {
                                arr.push({
                                    'name': 'nonce',
                                    'value' : productSettings.nonce
                                });
                            },
                            success: function(responseText, statusText, xhr) {
                                console.group('[Module] Products -> fn.GetProducts -> Success');
                                console.log('XHR: ', xhr);
                                console.log('Status: ', statusText);
                                console.log('Response: ', responseText);

                                var results = responseText.results,
                                    count   = responseText.count,
                                    len     = results.length,
                                    x       = 0;

                                config.count.to = count;

                                console.log('Results: ', results);
                                console.log('Count: ', count);

                                for (x; x < len; x++) {
                                    $('[data-module="sameheight"]', $wrp).append(template(results[x]));
                                }

                                if ($num.length > 0) {
                                    $num.countTo({
                                        from: config.count.from,
                                        to: config.count.to,
                                        speed: config.count.speed,
                                        refreshInterval: config.count.refreshInterval
                                    });
                                }

                                console.groupEnd();
                            },
                            error: function(response) {
                                console.group('[Module] Products -> fn.GetProducts -> Error');
                                console.log('Response: ', response);
                                console.groupEnd();
                            },
                            complete: function() {
                                $loader.removeClass('c-loader--visible');

                                $wrp
                                    .stop(true, true)
                                    .transit({
                                        opacity: 1
                                    }, 300, function() {
                                        setTimeout(function() {
                                            helper.reInstantiate($element.find('[data-module]'));
                                            blazy.get().revalidate();
                                        }, 200);
                                    });
                            }
                        });
                    }, 300);
                });
        }


        /* ------------------------------------------ */
        /**
        *   Products -> HandleFilterChange
        */
        function handleFilterChange(event) {
            resetFilters();

            $.when(getSelectedFilters())
                .promise()
                .then( function() {
                    getProducts();
                });
        }


        /* ------------------------------------------ */
        /**
        *   Products -> GetSelectedFilters
        */
        function getSelectedFilters() {
            var defer = $.Deferred();

            $filters.each( function(i, el) {
                var $filter = $(this);

                console.group('[Module] Products -> fn.GetSelectedFilters');
                console.log('Filter ($): ', $filter);
                console.log('Filter (len): ', $filter.length);

                var checked = $filter.is(':checked'),
                    id      = $filter.attr('id'),
                    name    = $filter.attr('name'),
                    rel     = $filter.attr('rel') || 'categories',
                    value   = $filter.val();

                var type    = rel === 'brand' ? 'brands' : 'categories',
                    index   = config.filters[type].indexOf(value);

                console.log('ID: ', id);
                console.log('Name: ', name);
                console.log('Value: ', value);
                console.log('Type: ', type);
                console.log('Checked: ', checked);
                console.log('Index: ', index);

                if (checked && index === -1) {
                    config.filters[type].push(value);
                }

                console.log('Selected filters: ', config.filters[type]);
                console.groupEnd();

                if (i === ($filters.length -1)) {
                    config.filters.categories.push(config.filters.parent);
                    defer.resolve();
                }
            });

            return defer;
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
