(function($, app) {
    'use strict';

    app.accordion = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            unique          = helper.returnUniqueHash(),
            namespace       = 'Accordion-' + unique,
            prefix          = 'c-accordion',
            hPrefix         = 'c-height-transition';

        var defaults        = {
            target: null
        };

        // Merge data-config with default settings
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);


        var $header         = $('.c-header'),
            $logo           = $('.c-logo', $header),
            $target;

        var $siblings       = $('[data-module="accordion"]', $element.parents('.c-section')),
            $targetSiblings = $('.js-' + prefix + '-target', $element.parents('.c-section'));

        var target = 0,
            offset = 0;

        var headerOffset    = $header.length > 0 ? (20 + $header.outerHeight()) : 0,
            logoOffset      = $logo.length > 0 ? (20 + $logo.outerHeight()) : 0;


        /* ------------------------------------------ */
        /**
        *   Accordion -> Initialize
        */
        function initialize() {
            if (config.target === null || config.target === '') return;

            $target = $('[id="' + config.target + '"]');

            addEventListeners();
        }


        /* ------------------------------------------ */
        /**
        *   Accordion -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            $element.on('click.' + namespace, handleClick);
        }


        /* ------------------------------------------ */
        /**
        *   Accordion -> RequestAnimationFrame
        */
        function requestAnimationFrame() {
            (function () {
                return  window.requestAnimationFrame        ||
                        window.webkitRequestAnimationFrame  ||
                        window.mozRequestAnimationFrame     ||
                        function (callback) {
                            window.setTimeout(callback, 1000 / 60);
                        };
            }());
        }


        /* ------------------------------------------ */
        /**
        *   Accordion -> HandleClick
        *   Event-handler for click-event
        */
        function handleClick(event) {
            event.preventDefault();
            event.stopPropagation();

            $siblings.not($element).removeClass(prefix + '--open');
            $targetSiblings.not($target)
                .removeAttr('style')
                .addClass(hPrefix + '--hidden');

            if ($target.length > 0) {
                if ($target.hasClass(hPrefix + '--hidden')) {
                    $target.slideDownTransition().removeClass(hPrefix + '--hidden');
                    $element.addClass(prefix + '--open');

                    setTimeout(scrollToTarget, 600);

                } else {
                    $target.slideUpTransition().addClass(hPrefix + '--hidden').removeAttr('style');
                    $element.removeClass(prefix + '--open');
                }
            }
        }


        /* ------------------------------------------ */
        /**
        *   Accordion -> ScrollToTarget
        */
        function scrollToTarget() {
            target = 0;
            offset = 0;

            offset = ($header.length > 0) ? (headerOffset) : 0;
            offset = ($logo.length > 0) ? (logoOffset) : 0;

            target  = $target.offset().top;
            target -= (offset *1.35);

            helper.scrollTo(target);
        }


        initialize();

    };
})(jQuery, window.app = window.app || {});
