(function($, app) {
    'use strict';

    app.paralax = function (element) {

        var $element        = $(element);
        var config          = $element.data('config'),
            helper          = new app.helper(),
            unique          = helper.returnUniqueHash(),
            namespace       = 'Paralax-' + unique,
            prefix          = 'c-paralax';

        var defaults        = {
            winHeight: 0,
            scrollTop: 0,
            scrollPos: 0,
            mod: 10,
            offset: {}
        };

        // Merge data-config with default settings
        if (typeof config === 'string' && config !== '') {
            config = JSON.parse(decodeURIComponent(config));
        }
        config = $.extend({}, defaults, config);


        /* ------------------------------------------ */
        /**
        *   Paralax -> Initialize
        */
        function initialize() {
            addEventListeners();
        }


        /* ------------------------------------------ */
        /**
        *   Paralax -> AddEventListeners
        *   Add the corresponding event-listeners
        */
        function addEventListeners() {
            $win.on('resize.' + namespace, handleResize);

            $win
                .on('scroll.' + namespace, handleScroll)
                .trigger('scroll.' + namespace);
        }


        /* ------------------------------------------ */
        /**
        *   Paralax -> handleResize
        *   Event-handler for resize-event
        */
        function handleResize(event) {
            config.winHeight = $win.outerHeight();
            config.scrollTop = $win.scrollTop();
            config.scrollPos = config.scrollTop + config.winHeight;

            config.offset = $element.offset();
        }


        /* ------------------------------------------ */
        /**
        *   Paralax -> handleScroll
        *   Event-handler for scroll-event
        */
        function handleScroll(event) {
            handleResize();

            if (config.scrollPos > config.offset.top + 200) {
                $element.removeClass('hide');
            }

            if (config.scrollPos > config.offset.top) {
                var nul  = config.scrollPos - config.offset.top,
                    prlx = nul / config.mod;

                $element.css({'transform': 'translate3d(0px, -' + prlx + 'px, 0px)'});
            }
        }

        initialize();

    };
})(jQuery, window.app = window.app || {});
